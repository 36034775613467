.menu-container {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: #faf7f5;
  color: black;
  padding: 0px 8px;
  font: Arial, sans-serif;
  border: none;
  cursor: pointer;
  position: relative;

  &:hover, &:focus {
    background-color: white;
  }

  @media (min-width: 950px) {
    display: none; 
  }
}

.hamburger-icon {
  font-size: 44px;  /* Set to whatever size you want */
  vertical-align: middle;
}

.dropdown {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 9999 !important;
  right: 0; // Aligns the dropdown's right edge with the button's right edge
  top: 100%; // Makes the dropdown appear below the button

  a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #d3d3d3;
    }
  }
}

.icon {
  margin-right: 8px; // Adjust as needed
  vertical-align: middle;
  font-size: 22px;
}

.button-container-menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  background: #faf7f5;
  height: 50px;
  position: fixed;
  z-index: 1000;

  > *:not(:first-child) {
    margin-left: auto;
  }
}

@media (min-width: 951px) {
  .button-container-menu {
    justify-content: center;
  }
  .button-container-menu > *:not(:first-child) {
    margin-left: 0;
  }
}