.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  // margin-bottom: -10px;
}

.title {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  // font-size: 18px;
  // font-weight: bold;
  white-space: nowrap;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // font-size: 18px;
}

.content p {
    text-align: center;
    margin-bottom: 0px;
    // font-size: 18px;
}

.input-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.input-section input {
    width: 120px;
    margin: 0 1px;
    text-align: center;
    font-size: 20px;
    height: 45px;
    border: 1px solid black;
    border-radius: 8px;
}

// .correct {
//     color: rgb(125, 121, 210);
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

// .incorrect {
//     // color: rgb(101, 98, 98);
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

.no-guesses {
    // color: rgb(101, 98, 98);
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // white-space: nowrap;
}

.solve-button {
    margin-left: 50px;
    width: 100px;
    height: 45px;
    // z-index: 1001;
    &:hover {
      cursor: pointer;
    }
}

.exp-button {
  // margin-left: 50px;
  // width: 100px;
  height: 45px;
  &:hover {
    cursor: pointer;
  }
}

.button-container {
    display: flex;
    justify-content: space-between; /* distribute buttons to the corners */
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    padding: 0 10px; /* little padding to not stick to the edges */
    background: white;
    height: 50px;
    // z-index: 2000;
  }
  
  .instruction-button {
    position: fixed;
    font-size: 26px;
    cursor: pointer;
    z-index: 1101;
  
    &:hover {
      transform: scale(1.1);
    }
  }

  .instruction-button-unl {
    position: fixed;
    font-size: 26px;
    cursor: pointer;
    z-index: 1101;
  
    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 950px) {
    .daily-streak {
        font-size: 24px;
      }
    .instruction-button {
      font-size: 26px;
      border: none !important;
      background: none !important;
      // position: absolute;
      right: 90px;
      top: 10px;
    }
    .instruction-button-unl {
      font-size: 26px;
      border: none !important;
      background: none !important;
      // position: absolute;
      right: 90px;
      top: 10px;
    }
    .share-button {
      font-size: 26px;
      border: none !important;
      background: none !important;
      position: absolute;
      right: 60px;
    }
    // .twitter-button {
    //   font-size: 26px;
    //   border: none !important;
    //   background: none !important;
    //   position: absolute;
    //   right: 105px;
    //   top: 12px;
    // }
    .trophy-button {
      font-size: 26px;
      border: none !important;
      background: none !important;
      position: absolute;
      color: black;
      left: 80px;
      top: 12px;
    }
    .archive-button {
      font-size: 26px;
      border: none !important;
      background: none !important;
      position: absolute;
      right: 60px;
      color: black;
      top: 12px;
    }
  }
  
  @media (min-width: 951px) {
    .button-container {
      justify-content: center;
      padding: 0; /* Remove padding */
    }
    .daily-streak {
      font-size: 26px;
      border: none !important;
      background: none !important;
      margin-right: 300px; /* Margin to push the button 300px from center */
    }
    .instruction-button {
      font-size: 28px;
      border: none !important;
      background: none !important;
      margin-left: 300px;
      top: 12px;
    }
    .instruction-button-unl {
      font-size: 28px;
      border: none !important;
      background: none !important;
      left: 50%;           /* Move the starting point to the center of the container */
      transform: translateX(100px); /* Move it 300px to the right from the center */
      top: 12px;
      transition: transform 0.3s ease; /* Smooth transition for transform property only */
    }
    .instruction-button-unl:hover {
      transform: translateX(100px) scale(1.1); /* Example: Slight increase in size on hover */
    }
    .share-button {
      display: none; /* Hide the share button on larger screens */
    }
    // .twitter-button {
    //   font-size: 30px;
    //   border: none !important;
    //   background: none !important;
    //   position: relative;
    //   left: 230px;
    //   top: 4px;
    // }
    .trophy-button {
      font-size: 28px;
      border: none !important;
      background: none !important;
      position: relative;
      left: -280px;
      color: black;
      top: 4px;
    }
    .archive-button {
      font-size: 26px;
      border: none !important;
      background: none !important;
      position: absolute;
      margin-left: 280px;
      color: black;
      top: 12px;
    }
    .dislike-options {
      flex-direction: row; /* horizontal alignment on larger screens */
    }
  }

.gs-chart-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 700px; /* Added max-width property */
    margin: 0 auto;
    min-height: 90vh;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.chart-title {
    text-align: center;
    margin-bottom: 20px;
    // transform: translateX(20px);
}

.close-button {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;
  border: none !important;
  background: none !important;
}

.alert-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-dialog {
  width: 400px;
  max-width: 80vw;
  margin: auto;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.01);
  max-height: 60vh;

}

.info-modal-dialog {
  z-index: 1060;
  // @media (min-width: 950px) {
  //   margin-left: 140px;
  // }
}

.modal-content {
  border: 2px solid #000;
  background-color: rgb(204, 202, 246);
  border-radius: 15px;
  padding: 20px;
}

.backdrop-test {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.backdrop-exp {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left:0%;
  background-color: rgba(0, 0, 0, 0.2);
}

.backdrop-show {
  display: block;
}

.logo {
  height: 50px;
  width: auto;
  margin-right: 10px;
  padding-top: 10px; 
  @media (min-width: 950px) {
    // margin-left: 280px;
    margin-right: 0px;
  }
}

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  padding-bottom: 20px;
}

.feedback-container p {
  margin-bottom: 10px;
  
}

/* Container for the like and dislike buttons */
.button-group {
  display: flex;
  gap: 10px; /* space between the buttons */
}

.like-button, .dislike-button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px; /* space between icon and text */
  border-radius: 8px; 
}

.like-button {
  border: 2px solid green;
}

.dislike-button {
  border: 2px solid red;
}

.dislike-options {
  display: flex;
  // flex-direction: column;
  align-items: center;
  gap: 10px;
}

.dislike-options button {
  margin: 5px;
  // padding: 0px 10px;
  border: 1px solid black; /* black border */
  border-radius: 25px; /* rounded edges */
  cursor: pointer;
}

.feedback-submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* take full height of the parent container */
  width: 100%;  /* take full width of the parent container */
  color: green;
}

// .exp-modal-dialog {
//   max-width: 600px; /* Set a maximum width for the modal */
//   // max-height: 70vh; /* Set the maximum height to 90% of the viewport height */
//   // overflow-y: auto; /* Add a scrollbar if the content exceeds the modal's height */
//   border-radius: 8px; /* Add rounded corners */
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
//   // margin: 50vh auto 5vh;
// }

@media (max-width: 768px) {
  .exp-modal-dialog {
    max-width: 80vw;
  }
}

.exp-modal-title {
  font-size: 1.2rem;
}

.exp-modal-body {
  overflow-y: auto; /* Add a scrollbar if the content exceeds the body's height */
  max-height: 60vh; /* Adjust this value based on your header/footer size */
}

// .exp-close-button {
//   background-color: transparent; /* Remove any background */
//   border: none; /* Remove borders */
//   color: #888; /* Set the color to a medium gray */
//   z-index: 10;
//   margin: 50vh auto 5vh;
// }

// .exp-close-button:hover {
//   color: #555; /* Darken the color on hover */
// }

.rotd {
  transform: translateY(-30px);
}

#nn_player {
  width: 50%;
  max-width: 650px;
}

.desktop-midpage{
  max-width: 650px !important;
  height: 300px;
  border: 1px solid black;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  margin-left: auto;
  margin-right: auto;

}

.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch; 
  width: 100%; 
  // height: 85vh; 
  // position: relative; 
}

.video-player, .right-content {
  display: none;
}

@media (min-width: 701px) {
  .video-player, .right-content {
    min-width: 0px;
    display: flex; 
    flex-direction: column; 
  }

  .video-player {
    position: fixed; /* Position relative to the viewport */
    bottom: 100px; /* Adjust padding from the bottom */
    left: 10px; /* Adjust padding from the left */
    display: flex; 
    flex-direction: column;
    align-items: flex-start; 
    justify-content: flex-end; 
    z-index: 1000; /* Ensure it's above other content */
  }

  .right-content {
    padding-top: 100px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0px;
    z-index: 1;
  }
}

.center-content {
  width: 400px;
  height: 100%;
  justify-content: center;
  align-items: center;
  // position: relative; 
}

.postgame_mpu1 {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  // height: 600px;
}

.blur-text {
  filter: blur(4px);
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* WebKit (Safari/Chrome) browsers */
  -moz-user-select: none; /* Mozilla browsers */
  -ms-user-select: none; /* Internet Explorer/Edge browsers */
}

.game-over-message {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
  // margin-top: 20px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 20px;
  // position: relative;
  // left: 50%;
  // transform: translateX(-50%);
}

.correct-result {
  background-color: #BBF7D0;
  // background-color: #28a745;
  // background-color: white;
  // border: 2px solid #28a745;
  color: black;



}

.incorrect-result {
  background-color: #FFFBEB;
}

.message-icon-result {
  margin-right: 10px;
}

.icon-correct, .icon-incorrect {
  font-size: 2rem;
  position: relative;
  top: 3px;
}

.icon-correct {
  color: black; 
}

.icon-incorrect {
  color: #E5A14F;
}

.solve-container {
  background-color: #e6e6fa;
  border: 1px solid #7D79D2;
  border-radius: 10px;
  margin-top: 10px;
  max-width: 300px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
