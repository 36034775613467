.archiveContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px; 
    min-height: 90vh;
}

@media (max-width: 700px) {
    .nav-container {
        display: flex;
        justify-content: space-between; /* distribute buttons to the corners */
        align-items: center;
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        padding: 0 10px; /* little padding to not stick to the edges */
        background: white;
        height: 50px;
    }
    .home-button {
        font-size: 28px;
        color: black;
        margin-left: 50 !important;
        cursor: pointer;
    }
    .archive-button-archive {
        margin-left: 20px !important;
        font-size: 24px;
        cursor: pointer;
    }
}

@media (min-width: 701px) {
    .nav-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0px;
        left: 0;
        right: 0;
        padding: 0;
        height: 50px;
    }
    .home-button {
        font-size: 30px;
        color: black;
        margin-right: 280px;
        cursor: pointer;
    }
    .archive-button-archive {
        font-size: 28px;
        color: black;
        cursor: pointer;
    }
}

.buttonStyle {
    background-color: rgb(204, 202, 246) !important;
    color: black !important;
    margin: 10px 0 !important;
    width: 300px;
    position: fixed;
  
    &:hover {
      background-color: #7e57c2 !important;
    }
}

.button-container-archive {
    display: flex;
    justify-content: space-between; /* distribute buttons to the corners */
    align-items: center;
    top: 0px;
    left: 0;
    right: 0;
    width: 100%; /* ensure full width */
    margin: 0; /* remove any margins */
    padding: 0 10px; /* little padding to not stick to the edges */
    background: white;
    height: 50px;
    
    @media (min-width: 701px) { /* screen size greater than 701 pixels */
        justify-content: center; /* center the items */
        
        > * { /* direct children of the button-container */
            position: relative;
            
            &:first-child { /* first button */
                left: -250px;
            }
            
            &:last-child { /* last button */
                right: -250px;
            }
        }
    }
}

.archive-mpu-container {
    display: flex;
    justify-content: center;
}

.archive-back-button {
    padding: 5px 10px; /* Padding to make the button taller and wider */
    text-align: center; /* Center text inside the button */
    margin: 20px auto; /* Margin auto for horizontal centering */
    cursor: pointer; /* Cursor to pointer to indicate it's clickable */
}

.unlimited-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 90%;
  
    @media (min-width: 950px) {
      max-width: 40%;
    }
  }